<template lang="pug">
v-dialog(v-model="dialogLogin", max-width="400", persistent)
  v-card
    v-btn.float-right(
      color="darken-1",
      text="",
      fab="",
      @click="closeDialog",
      :ripple="false"
    ) 
      v-icon(color="#ABC9E3") mdi-close
    v-card-title.clear.justify-center
      img.logo(:src="logo", target="_blank")
      h1.text-center.login-title 會員登入

    v-card-text.text-center.mt-3
      form.form-login(style="margin-top: 40px")
        .input_container
          label.sr-only(for="id_username") 會員Email帳號
          input#id_username(
            required,
            type="email",
            name="username",
            placeholder="會員 Email 帳號",
            autofocus="",
            v-model="email"
          )
          svg-icon(iconClass="user_hollow", className="user_hollow")
        .input_container.mt-6
          label.sr-only(for="id_password") 會員密碼
          input#id_password(
            required,
            type="password",
            name="password",
            placeholder="設定會員密碼",
            v-model="password"
          )
          svg-icon(iconClass="key_hollow", className="key_hollow")
        span.fgp_btn(style="cursor: pointer", @click="toForgetPassword") 忘記密碼？
        .btn.btn.login.w-100(style="margin-top: 36px", @click="loginBtnClick") 登入
        .or.text-center
          span OR
        .btn.btn.sign_up(@click="toRegister") 前往註冊

  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import Logo from "@/assets/img/sign_up/logo.png";
import { mapActions } from "vuex";
import { validEmail, validStringEmpty } from "@/utils/validate";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";

export default {
  name: "DialogLogin",
  props: {
    title: String,
    dialogLogin: Boolean,
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      logo: Logo,

      email: "",
      password: "",

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
    };
  },
  methods: {
    ...mapActions("user", ["login"]),
    loginBtnClick() {
      if (validStringEmpty(this.email) || validStringEmpty(this.password)) {
        this.showErrorDialog("錯誤", "請輸入帳號密碼");
        return;
      }
      if (!validEmail(this.email)) {
        this.showErrorDialog("帳號錯誤", "信箱格式錯誤");
        return;
      }
      this.showLoadingDialog("登入中");
      this.login({ email: this.email, password: this.password })
        .then(() => {
          this.closeLoadingDialog();
          this.loginSuccess();
        })
        .catch(() => {
          this.closeLoadingDialog();
          this.showErrorDialog("登入失敗", "請確認帳號密碼");
        });
    },
    toRegister() {
      this.$router.push({ name: "Register" });
    },
    toForgetPassword() {
      this.$router.push({ name: "ForgetPassword" });
    },
    closeDialog() {
      this.$emit("closeLoginDialog");
    },
    loginSuccess() {
      this.$emit("loginSuccess");
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/user/login";

.fa-envelope-open-text {
  width: 50px;
  height: 50px;
  color: $primary;
}

input,
textarea {
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #487aa4;
}

textarea {
  height: 100px;
}

::v-deep .v-dialog {
  width: 100%;
}
</style>
