<template lang="pug">
v-dialog(v-model="dialogFeedback", max-width="400", persistent)
  v-card
    v-btn.float-right(
      color="darken-1",
      text="",
      fab="",
      @click="closeDialog",
      :ripple="false"
    ) 
      v-icon(color="#ABC9E3") mdi-close
    v-card-title.clear.flex-column.justify-center
      .text-h5 問題回報
      .mt-1
        v-icon mdi-alert-octagon

    v-card-text.text-center.mt-3
      .input_container.mt-6
        input(
          required,
          type="text",
          name="title",
          placeholder="主旨",
          v-model="title"
        )
      .input_container.mt-6
        textarea(
          required,
          type="text",
          name="content",
          placeholder="回報內容",
          v-model="description"
        )
    v-card-actions
      v-spacer
      v-btn(color="darken-1", text="", @click="feedBackConfirm") 送出表單
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";
import { playErrorReport } from "@/api/error_report";
import { validStringEmpty } from "@/utils/validate";

export default {
  name: "DialogFeedback",
  props: {
    dialogFeedback: Boolean,
    play: Object,
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      title: "",
      description: "",

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
    };
  },
  methods: {
    feedBackConfirm() {
      if (validStringEmpty(this.title) || validStringEmpty(this.description)) {
        this.showErrorDialog("錯誤", "請填寫回報內容");
        return;
      }
      let temp = {
        title: this.title,
        description: this.description,
        play: this.play.id,
        league: this.play.league,
        team: this.play.team,
        player: this.play.player_name,
        youtube_id: this.play.video,
        video_start_at: this.play.video_start_at,
        video_end_at: this.play.video_end_at,
        game: this.play.game_name,
        result: this.play.result,
      };
      this.showLoadingDialog("傳送中...");
      playErrorReport(temp)
        .then(() => {
          this.closeLoadingDialog();
          this.showSuccessDialog(
            "回報成功",
            "問題已經回報，我們會盡速修正！謝謝您的協助。"
          );
          setTimeout(() => {
            this.closeSuccessDialog();
            this.closeDialog();
          }, 2500);
        })
        .catch((error) => {
          this.closeLoadingDialog();
          this.showErrorDialog("傳送失敗", error.response.data.error_msg);
        });
    },
    closeDialog() {
      this.$emit("closeFeedbackDialog");
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
input,
textarea {
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #487aa4;
}

textarea {
  height: 100px;
}

::v-deep .v-dialog {
  width: 100%;
}
</style>
